(function () {
  var k = this;
    var l = function (a, b) {
      a = a.split('.');
      var c = k;
      a[0] in c || typeof c.execScript == 'undefined' || c.execScript('var ' + a[0]);
      for (var d; a.length && (d = a.shift());)
        {a.length || void 0 === b ? (c = c[d] && c[d] !== Object.prototype[d] ? c[d] : (c[d] = {})) : (c[d] = b);}
    };
  var m = function (a, b) {
      for (var c in b) b.hasOwnProperty(c) && (a[c] = b[c]);
    };
    var n = function (a) {
      for (var b in a) if (a.hasOwnProperty(b)) return !0;
      return !1;
    };
  var q = /^(?:(?:https?|mailto|ftp):|[^:/?#]*(?:[/?#]|$))/i;
  var r = window;
    var t = document;
    var u = function (a, b) {
      t.addEventListener ? t.addEventListener(a, b, !1) : t.attachEvent && t.attachEvent('on' + a, b);
    };
  var v = /:[0-9]+$/;
    var x = function(a, b) {
      b && (b = String(b).toLowerCase());
      if ('protocol' === b || 'port' === b) a.protocol = w(a.protocol) || w(r.location.protocol);
      'port' === b
        ? (a.port = String(
            Number(a.hostname ? a.port : r.location.port) ||
              ('http' == a.protocol ? 80 : 'https' == a.protocol ? 443 : ''),
          ))
        : 'host' === b && (a.hostname = (a.hostname || r.location.hostname).replace(v, '').toLowerCase());
      var c = w(a.protocol);
      b && (b = String(b).toLowerCase());
      switch (b) {
        case 'url_no_fragment':
          b = '';
          a && a.href && ((b = a.href.indexOf('#')), (b = 0 > b ? a.href : a.href.substr(0, b)));
          a = b;
          break;
        case 'protocol':
          a = c;
          break;
        case 'host':
          a = a.hostname.replace(v, '').toLowerCase();
          break;
        case 'port':
          a = String(Number(a.port) || ('http' == c ? 80 : 'https' == c ? 443 : ''));
          break;
        case 'path':
          a = '/' == a.pathname.substr(0, 1) ? a.pathname : '/' + a.pathname;
          a = a.split('/');
          a: if (((b = a[a.length - 1]), (c = []), Array.prototype.indexOf))
            (b = c.indexOf(b)), (b = 'number' == typeof b ? b : -1);
          else {
            for (var d = 0; d < c.length; d++)
              if (c[d] === b) {
                b = d;
                break a;
              }
            b = -1;
          }
          0 <= b && (a[a.length - 1] = '');
          a = a.join('/');
          break;
        case 'query':
          a = a.search.replace('?', '');
          break;
        case 'extension':
          a = a.pathname.split('.');
          a = 1 < a.length ? a[a.length - 1] : '';
          a = a.split('/')[0];
          break;
        case 'fragment':
          a = a.hash.replace('#', '');
          break;
        default:
          a = a && a.href;
      }
      return a;
    };
    var w = function(a) {
      return a ? a.replace(':', '').toLowerCase() : '';
    };
    var y = function (a) {
      var b = t.createElement('a');
      a && (b.href = a);
      a = b.pathname;
      a[0] !== '/' && (a = '/' + a);
      var c = b.hostname.replace(v, '');
      return {
        href: b.href,
        protocol: b.protocol,
        host: b.host,
        hostname: c,
        pathname: a,
        search: b.search,
        hash: b.hash,
        port: b.port,
      };
    };
  function z () {
    for (var a = A, b = {}, c = 0; c < a.length; ++c) b[a[c]] = c;
    return b;
  }
  function B () {
    var a = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    a += a.toLowerCase() + '0123456789-_';
    return a + '.';
  }
  var A;
    var C;
    var D = function(a) {
      A = A || B();
      C = C || z();
      for (var b = [], c = 0; c < a.length; c += 3) {
        var d = c + 1 < a.length,
          e = c + 2 < a.length,
          g = a.charCodeAt(c),
          f = d ? a.charCodeAt(c + 1) : 0,
          h = e ? a.charCodeAt(c + 2) : 0,
          p = g >> 2;
        g = ((g & 3) << 4) | (f >> 4);
        f = ((f & 15) << 2) | (h >> 6);
        h &= 63;
        e || ((h = 64), d || (f = 64));
        b.push(A[p], A[g], A[f], A[h]);
      }
      return b.join('');
    };
    var E = function (a) {
      function b (b) {
        for (; d < a.length;) {
          var c = a.charAt(d++);
            var e = C[c];
          if (e != null) return e;
          if (!/^[\s\xa0]*$/.test(c)) throw Error('Unknown base64 encoding at char: ' + c);
        }
        return b;
      }
      A = A || B();
      C = C || z();
      for (var c = '', d = 0; ;) {
        var e = b(-1);
          var g = b(0);
          var f = b(64);
          var h = b(64);
        if (h === 64 && e === -1) return c;
        c += String.fromCharCode((e << 2) | (g >> 4));
        f != 64 &&
          ((c += String.fromCharCode(((g << 4) & 240) | (f >> 2))),
          h != 64 && (c += String.fromCharCode(((f << 6) & 192) | h)));
      }
    };
  var F;
  function G (a, b) {
    if (!a || b === t.location.hostname) return !1;
    for (var c = 0; c < a.length; c++)
      {if (a[c] instanceof RegExp) {
        if (a[c].test(b)) return !0;
      } else if (0 <= b.indexOf(a[c])) return !0;}
    return !1;
  }
  var H = function () {
    var a = {};
    var b = r.google_tag_data;
    r.google_tag_data = void 0 === b ? a : b;
    a = r.google_tag_data;
    b = a.gl;
    (b && b.decorators) || ((b = { decorators: [] }), (a.gl = b));
    return b;
  };
  var I = /(.*?)\*(.*?)\*(.*)/;
    var J = /([^?#]+)(\?[^#]*)?(#.*)?/;
    var K = /(.*?)(^|&)_gl=([^&]*)&?(.*)/;
    var M = function(a) {
      var b = [],
        c;
      for (c in a)
        if (a.hasOwnProperty(c)) {
          var d = a[c];
          void 0 !== d &&
            d === d &&
            null !== d &&
            '[object Object]' !== d.toString() &&
            (b.push(c), b.push(D(String(d))));
        }
      a = b.join('*');
      return ['1', L(a), a].join('*');
    };
    var L = function(a, b) {
      a = [
        window.navigator.userAgent,
        new Date().getTimezoneOffset(),
        window.navigator.userLanguage || window.navigator.language,
        Math.floor(new Date().getTime() / 60 / 1e3) - (void 0 === b ? 0 : b),
        a,
      ].join('*');
      if (!(b = F)) {
        b = Array(256);
        for (var c = 0; 256 > c; c++) {
          for (var d = c, e = 0; 8 > e; e++) d = d & 1 ? (d >>> 1) ^ 3988292384 : d >>> 1;
          b[c] = d;
        }
      }
      F = b;
      b = 4294967295;
      for (c = 0; c < a.length; c++) b = (b >>> 8) ^ F[(b ^ a.charCodeAt(c)) & 255];
      return ((b ^ -1) >>> 0).toString(36);
    };
    var P = function (a) {
      return function (b) {
        var c = y(r.location.href);
          var d = c.search.replace('?', '');
        a: {
          var e = d.split('&');
          for (var g = 0; g < e.length; g++) {
            var f = e[g].split('=');
            if (decodeURIComponent(f[0]).replace(/\+/g, ' ') === '_gl') {
              e = f.slice(1).join('=');
              break a;
            }
          }
          e = void 0;
        }
        b.query = N(e || '') || {};
        e = x(c, 'fragment');
        g = e.match(K);
        b.fragment = N((g && g[3]) || '') || {};
        a && O(c, d, e);
      };
    };
  function Q (a) {
    var b = K.exec(a);
    if (b) {
      var c = b[2];
        var d = b[4];
      a = b[1];
      d && (a = a + c + d);
    }
    return a;
  }
  var O = function (a, b, c) {
      function d (a, b) {
        a = Q(a);
        a.length && (a = b + a);
        return a;
      }
      r.history &&
        r.history.replaceState &&
        (K.test(b) || K.test(c)) &&
        ((a = x(a, 'path')), (b = d(b, '?')), (c = d(c, '#')), r.history.replaceState({}, void 0, '' + a + b + c));
    };
    var N = function (a) {
      var b = void 0 === b ? 3 : b;
      try {
        if (a) {
          a: {
            for (var c = 0; c < 3; ++c) {
              var d = I.exec(a);
              if (d) {
                var e = d;
                break a;
              }
              a = decodeURIComponent(a);
            }
            e = void 0;
          }
          if (e && e[1] === '1') {
            var g = e[2];
              var f = e[3];
            a: {
              for (e = 0; e < b; ++e)
                {if (g === L(f, e)) {
                  var h = !0;
                  break a;
                }}
              h = !1;
            }
            if (h) {
              b = {};
              var p = f ? f.split('*') : [];
              for (f = 0; f < p.length; f += 2) b[p[f]] = E(p[f + 1]);
              return b;
            }
          }
        }
      } catch (X) {}
    };
  function R (a, b, c) {
    function d (a) {
      a = Q(a);
      var b = a.charAt(a.length - 1);
      a && b !== '&' && (a += '&');
      return a + f;
    }
    c = void 0 === c ? !1 : c;
    var e = J.exec(b);
    if (!e) return '';
    b = e[1];
    var g = e[2] || '';
    e = e[3] || '';
    var f = '_gl=' + a;
    c ? (e = '#' + d(e.substring(1))) : (g = '?' + d(g.substring(1)));
    return '' + b + g + e;
  }
  function S (a, b, c) {
    for (var d = {}, e = {}, g = H().decorators, f = 0; f < g.length; ++f) {
      var h = g[f];
      (!c || h.forms) && G(h.domains, b) && (h.fragment ? m(e, h.callback()) : m(d, h.callback()));
    }
    n(d) && ((b = M(d)), c ? T(b, a) : U(b, a, !1));
    !c && n(e) && ((c = M(e)), U(c, a, !0));
  }
  function U (a, b, c) {
    b.href && ((a = R(a, b.href, void 0 === c ? !1 : c)), q.test(a) && (b.href = a));
  }
  function T (a, b) {
    if (b && b.action) {
      var c = (b.method || '').toLowerCase();
      if (c === 'get') {
        c = b.childNodes || [];
        for (var d = !1, e = 0; e < c.length; e++) {
          var g = c[e];
          if (g.name === '_gl') {
            g.setAttribute('value', a);
            d = !0;
            break;
          }
        }
        d ||
          ((c = t.createElement('input')),
          c.setAttribute('type', 'hidden'),
          c.setAttribute('name', '_gl'),
          c.setAttribute('value', a),
          b.appendChild(c));
      } else c === 'post' && ((a = R(a, b.action)), q.test(a) && (b.action = a));
    }
  }
  var V = function (a) {
      try {
        a: {
          var b = a.target || a.srcElement || {};
          for (a = 100; b && a > 0;) {
            if (b.href && b.nodeName.match(/^a(?:rea)?$/i)) {
              var c = b;
              break a;
            }
            b = b.parentNode;
            a--;
          }
          c = null;
        }
        if (c) {
          var d = c.protocol;
          (d !== 'http:' && d !== 'https:') || S(c, c.hostname, !1);
        }
      } catch (e) {}
    };
    var W = function (a) {
      try {
        var b = a.target || a.srcElement || {};
        if (b.action) {
          var c = x(y(b.action), 'host');
          S(b, c, !0);
        }
      } catch (d) {}
    };
  l('google_tag_data.glBridge.auto', function (a, b, c, d) {
    var e = H();
    e.init || (u('mousedown', V), u('keyup', V), u('submit', W), (e.init = !0));
    a = { callback: a, domains: b, fragment: c === 'fragment', forms: !!d };
    H().decorators.push(a);
  });
  l('google_tag_data.glBridge.decorate', function (a, b, c) {
    c = !!c;
    a = M(a);
    if (b.tagName) {
      if (b.tagName.toLowerCase() == 'a') return U(a, b, c);
      if (b.tagName.toLowerCase() == 'form') return T(a, b);
    }
    if (typeof b == 'string') return R(a, b, c);
  });
  l('google_tag_data.glBridge.generate', M);
  l('google_tag_data.glBridge.get', function (a, b) {
    var c = P(!!b);
    b = H();
    b.data || ((b.data = { query: {}, fragment: {} }), c(b.data));
    c = {};
    if ((b = b.data)) m(c, b.query), a && m(c, b.fragment);
    return c;
  });
})(window);
(function () {
  function La (a) {
    var b = 1;
      var c;
    if (a)
      {for (b = 0, c = a.length - 1; 0 <= c; c--) {
        var d = a.charCodeAt(c);
        b = ((b << 6) & 268435455) + d + (d << 14);
        d = b & 266338304;
        b = 0 != d ? b ^ (d >> 21) : b;
      }}
    return b;
  }
  var $c = function (a) {
    this.w = a || [];
  };
  $c.prototype.set = function (a) {
    this.w[a] = !0;
  };
  $c.prototype.encode = function () {
    for (var a = [], b = 0; b < this.w.length; b++) this.w[b] && (a[Math.floor(b / 6)] ^= 1 << b % 6);
    for (b = 0; b < a.length; b++)
      {a[b] = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_'.charAt(a[b] || 0);}
    return a.join('') + '~';
  };
  var vd = new $c();
  function J (a) {
    vd.set(a);
  }
  var Td = function (a) {
      a = Dd(a);
      a = new $c(a);
      for (var b = vd.w.slice(), c = 0; c < a.w.length; c++) b[c] = b[c] || a.w[c];
      return new $c(b).encode();
    };
    var Dd = function (a) {
      a = a.get(Gd);
      ka(a) || (a = []);
      return a;
    };
  var ea = function (a) {
      return typeof a == 'function';
    };
    var ka = function(a) {
      return '[object Array]' == Object.prototype.toString.call(Object(a));
    };
    var qa = function(a) {
      return void 0 != a && -1 < (a.constructor + '').indexOf('String');
    };
    var D = function(a, b) {
      return 0 == a.indexOf(b);
    };
    var sa = function(a) {
      return a ? a.replace(/^[\s\xa0]+|[\s\xa0]+$/g, '') : '';
    };
    var ra = function() {
      for (
        var a = O.navigator.userAgent + (M.cookie ? M.cookie : '') + (M.referrer ? M.referrer : ''),
          b = a.length,
          c = O.history.length;
        0 < c;

      )
        a += c-- ^ b++;
      return [hd() ^ (La(a) & 2147483647), Math.round(new Date().getTime() / 1e3)].join('.');
    };
    var ta = function(a) {
      var b = M.createElement('img');
      b.width = 1;
      b.height = 1;
      b.src = a;
      return b;
    };
    var ua = function() {};
    var K = function(a) {
      if (encodeURIComponent instanceof Function) return encodeURIComponent(a);
      J(28);
      return a;
    };
    var L = function(a, b, c, d) {
      try {
        a.addEventListener ? a.addEventListener(b, c, !!d) : a.attachEvent && a.attachEvent('on' + b, c);
      } catch (e) {
        J(27);
      }
    };
    var f = /^[\w\-:/.?=&%!\[\]]+$/;
    var Nd = /^[\w+/_-]+[=]{0,2}$/;
    var wa = function(a, b, c) {
      if (a) {
        var d = (M.querySelector && M.querySelector('script[nonce]')) || null;
        d = d ? d.nonce || (d.getAttribute && d.getAttribute('nonce')) || '' : '';
        if (c) {
          var e = (c = '');
          b && f.test(b) && (c = ' id="' + b + '"');
          d && Nd.test(d) && (e = ' nonce="' + d + '"');
          f.test(a) && M.write('<script' + c + e + ' src="' + a + '">\x3c/script>');
        } else
          (c = M.createElement('script')),
            (c.type = 'text/javascript'),
            (c.async = !0),
            (c.src = a),
            b && (c.id = b),
            d && c.setAttribute('nonce', d),
            (a = M.getElementsByTagName('script')[0]),
            a.parentNode.insertBefore(c, a);
      }
    };
    var be = function(a, b) {
      return E(M.location[b ? 'href' : 'search'], a);
    };
    var E = function(a, b) {
      return (a = a.match('(?:&|#|\\?)' + K(b).replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1') + '=([^&#]*)')) &&
        2 == a.length
        ? a[1]
        : '';
    };
    var xa = function() {
      var a = '' + M.location.hostname;
      return 0 == a.indexOf('www.') ? a.substring(4) : a;
    };
    var de = function(a, b) {
      var c = a.indexOf(b);
      if (5 == c || 6 == c) if (((a = a.charAt(c + b.length)), '/' == a || '?' == a || '' == a || ':' == a)) return !0;
      return !1;
    };
    var ya = function(a, b) {
      var c = M.referrer;
      if (/^(https?|android-app):\/\//i.test(c)) {
        if (a) return c;
        a = '//' + M.location.hostname;
        if (!de(c, a)) return b && ((b = a.replace(/\./g, '-') + '.cdn.ampproject.org'), de(c, b)) ? void 0 : c;
      }
    };
    var za = function (a, b) {
      if (b.length == 1 && b[0] != null && typeof b[0] === 'object') return b[0];
      for (var c = {}, d = Math.min(a.length + 1, b.length), e = 0; e < d; e++)
        {if ('object' === typeof b[e]) {
          for (var g in b[e]) b[e].hasOwnProperty(g) && (c[g] = b[e][g]);
          break;
        } else e < a.length && (c[a[e]] = b[e]);}
      return c;
    };
  var ee = function () {
    this.keys = [];
    this.values = {};
    this.m = {};
  };
  ee.prototype.set = function (a, b, c) {
    this.keys.push(a);
    c ? (this.m[':' + a] = b) : (this.values[':' + a] = b);
  };
  ee.prototype.get = function (a) {
    return this.m.hasOwnProperty(':' + a) ? this.m[':' + a] : this.values[':' + a];
  };
  ee.prototype.map = function (a) {
    for (var b = 0; b < this.keys.length; b++) {
      var c = this.keys[b];
        var d = this.get(c);
      d && a(c, d);
    }
  };
  var O = window;
    var M = document;
    var va = function (a, b) {
      return setTimeout(a, b);
    };
  var F = window;
    var Ea = document;
    var G = function (a) {
      var b = F._gaUserPrefs;
      if ((b && b.ioo && b.ioo()) || (a && !0 === F['ga-disable-' + a])) return !0;
      try {
        var c = F.external;
        if (c && c._gaUserPrefs && c._gaUserPrefs == 'oo') return !0;
      } catch (g) {}
      a = [];
      b = String(Ea.cookie || document.cookie).split(';');
      for (c = 0; c < b.length; c++) {
        var d = b[c].split('=');
          var e = d[0].replace(/^\s*|\s*$/g, '');
        e &&
          e == 'AMP_TOKEN' &&
          ((d = d
            .slice(1)
            .join('=')
            .replace(/^\s*|\s*$/g, '')) && (d = decodeURIComponent(d)),
          a.push(d));
      }
      for (b = 0; b < a.length; b++) if (a[b] == '$OPT_OUT') return !0;
      return !1;
    };
  var Ca = function (a) {
      var b = [];
        var c = M.cookie.split(';');
      a = new RegExp('^\\s*' + a + '=\\s*(.*?)\\s*$');
      for (var d = 0; d < c.length; d++) {
        var e = c[d].match(a);
        e && b.push(e[1]);
      }
      return b;
    };
    var zc = function(a, b, c, d, e, g) {
      e = G(e) ? !1 : eb.test(M.location.hostname) || ('/' == c && vc.test(d)) ? !1 : !0;
      if (!e) return !1;
      b && 1200 < b.length && (b = b.substring(0, 1200));
      c = a + '=' + b + '; path=' + c + '; ';
      g && (c += 'expires=' + new Date(new Date().getTime() + g).toGMTString() + '; ');
      d && 'none' !== d && (c += 'domain=' + d + ';');
      d = M.cookie;
      M.cookie = c;
      if (!(d = d != M.cookie))
        a: {
          a = Ca(a);
          for (d = 0; d < a.length; d++)
            if (b == a[d]) {
              d = !0;
              break a;
            }
          d = !1;
        }
      return d;
    };
    var Cc = function(a) {
      return encodeURIComponent
        ? encodeURIComponent(a)
            .replace(/\(/g, '%28')
            .replace(/\)/g, '%29')
        : a;
    };
    var vc = /^(www\.)?google(\.com?)?(\.[a-z]{2})?$/;
    var eb = /(^|\.)doubleclick\.net$/i;
  var oc;
    var Id = /^.*Version\/?(\d+)[^\d].*$/i;
    var ne = function () {
      if (void 0 !== O.__ga4__) return O.__ga4__;
      if (void 0 === oc) {
        var a = O.navigator.userAgent;
        if (a) {
          var b = a;
          try {
            b = decodeURIComponent(a);
          } catch (c) {}
          if (
            (a =
              !(b.indexOf('Chrome') >= 0) &&
              !(b.indexOf('CriOS') >= 0) &&
              (b.indexOf('Safari/') >= 0 || b.indexOf('Safari,') >= 0))
          )
            {(b = Id.exec(b)), (a = 11 <= (b ? Number(b[1]) : -1));}
          oc = a;
        } else oc = !1;
      }
      return oc;
    };
  var Fa;
    var Ga;
    var fb;
    var Ab;
    var ja = /^https?:\/\/[^/]*cdn\.ampproject\.org\//;
    var Ue = /^(?:www\.|m\.|amp\.)+/;
    var Ub = [];
    var da = function(a) {
      a: {
        if (ja.test(M.referrer)) {
          var b = M.location.hostname.replace(Ue, '');
          b: {
            var c = M.referrer;
            c = c.replace(/^https?:\/\//, '');
            var d = c.replace(/^[^/]+/, '').split('/'),
              e = d[2];
            d = (d = 's' == e ? d[3] : e) ? decodeURIComponent(d) : d;
            if (!d) {
              if (0 == c.indexOf('xn--')) {
                c = '';
                break b;
              }
              (c = c.match(/(.*)\.cdn\.ampproject\.org\/?$/)) &&
                2 == c.length &&
                (d = c[1].replace(/-/g, '.').replace(/\.\./g, '-'));
            }
            c = d ? d.replace(Ue, '') : '';
          }
          if (b == c) {
            b = !0;
            break a;
          } else J(78);
        }
        b = !1;
      }
      if (
        b &&
        !1 !== a[Kd] &&
        (void 0 === Ab && (b = ((b = De.get()) && b._ga) || void 0) && ((Ab = b), J(81)), void 0 !== Ab)
      )
        return a[Q] || (a[Q] = Ab), !1;
      if (a[Kd]) {
        J(67);
        if (a[ac] && 'cookie' != a[ac]) return !1;
        if (void 0 !== Ab) a[Q] || (a[Q] = Ab);
        else {
          a: if (
            ((b = String(a[W] || xa())),
            (c = String(a[Yb] || '/')),
            (d = Ca(String(a[U] || '_ga'))),
            (b = na(d, b, c)),
            !b || jd.test(b))
          )
            b = !0;
          else if (((b = Ca('AMP_TOKEN')), 0 == b.length)) b = !0;
          else {
            if (
              1 == b.length &&
              ((b = decodeURIComponent(b[0])),
              '$RETRIEVING' == b || '$OPT_OUT' == b || '$ERROR' == b || '$NOT_FOUND' == b)
            ) {
              b = !0;
              break a;
            }
            b = !1;
          }
          if (b && tc(ic, String(a[Na]))) return !0;
        }
      }
      return !1;
    };
    var ic = function() {
      Z.D([ua]);
    };
    var tc = function(a, b) {
      var c = Ca('AMP_TOKEN');
      if (1 < c.length) return J(55), !1;
      c = decodeURIComponent(c[0] || '');
      if ('$OPT_OUT' == c || '$ERROR' == c || G(b)) return J(62), !1;
      if (!ja.test(M.referrer) && '$NOT_FOUND' == c) return J(68), !1;
      if (void 0 !== Ab)
        return (
          J(56),
          va(function() {
            a(Ab);
          }, 0),
          !0
        );
      if (Fa) return Ub.push(a), !0;
      if ('$RETRIEVING' == c)
        return (
          J(57),
          va(function() {
            tc(a, b);
          }, 1e4),
          !0
        );
      Fa = !0;
      (c && '$' != c[0]) || (xc('$RETRIEVING', 3e4), setTimeout(Mc, 3e4), (c = ''));
      return Pc(c, b) ? (Ub.push(a), !0) : !1;
    };
    var Pc = function(a, b, c) {
      if (!window.JSON) return J(58), !1;
      var d = O.XMLHttpRequest;
      if (!d) return J(59), !1;
      var e = new d();
      if (!('withCredentials' in e)) return J(60), !1;
      e.open(
        'POST',
        (c || 'https://ampcid.google.com/v1/publisher:getClientId') + '?key=AIzaSyA65lEHUEizIsNtlbNo-l2K18dT680nsaM',
        !0,
      );
      e.withCredentials = !0;
      e.setRequestHeader('Content-Type', 'text/plain');
      e.onload = function() {
        Fa = !1;
        if (4 == e.readyState) {
          try {
            200 != e.status && (J(61), Qc('', '$ERROR', 3e4));
            var d = JSON.parse(e.responseText);
            d.optOut
              ? (J(63), Qc('', '$OPT_OUT', 31536e6))
              : d.clientId
              ? Qc(d.clientId, d.securityToken, 31536e6)
              : !c && d.alternateUrl
              ? (Ga && clearTimeout(Ga), (Fa = !0), Pc(a, b, d.alternateUrl))
              : (J(64), Qc('', '$NOT_FOUND', 36e5));
          } catch (ca) {
            J(65), Qc('', '$ERROR', 3e4);
          }
          e = null;
        }
      };
      d = { originScope: 'AMP_ECID_GOOGLE' };
      a && (d.securityToken = a);
      e.send(JSON.stringify(d));
      Ga = va(function() {
        J(66);
        Qc('', '$ERROR', 3e4);
      }, 1e4);
      return !0;
    };
    var Mc = function() {
      Fa = !1;
    };
    var xc = function(a, b) {
      if (void 0 === fb) {
        fb = '';
        for (var c = id(), d = 0; d < c.length; d++) {
          var e = c[d];
          if (zc('AMP_TOKEN', encodeURIComponent(a), '/', e, '', b)) {
            fb = e;
            return;
          }
        }
      }
      zc('AMP_TOKEN', encodeURIComponent(a), '/', fb, '', b);
    };
    var Qc = function (a, b, c) {
      Ga && clearTimeout(Ga);
      b && xc(b, c);
      Ab = a;
      b = Ub;
      Ub = [];
      for (c = 0; c < b.length; c++) b[c](a);
    };
  var oe = function () {
      return (Ba || M.location.protocol == 'https:' ? 'https:' : 'http:') + '//www.google-analytics.com';
    };
    var Da = function(a) {
      this.name = 'len';
      this.message = a + '-8192';
    };
    var ba = function(a, b, c) {
      c = c || ua;
      if (2036 >= b.length) wc(a, b, c);
      else if (8192 >= b.length) x(a, b, c) || wd(a, b, c) || wc(a, b, c);
      else throw (ge('len', b.length), new Da(b.length));
    };
    var pe = function(a, b, c, d) {
      d = d || ua;
      wd(a + '?' + b, '', d, c);
    };
    var wc = function(a, b, c) {
      var d = ta(a + '?' + b);
      d.onload = d.onerror = function() {
        d.onload = null;
        d.onerror = null;
        c();
      };
    };
    var wd = function(a, b, c, d) {
      var e = O.XMLHttpRequest;
      if (!e) return !1;
      var g = new e();
      if (!('withCredentials' in g)) return !1;
      a = a.replace(/^http:/, 'https:');
      g.open('POST', a, !0);
      g.withCredentials = !0;
      g.setRequestHeader('Content-Type', 'text/plain');
      g.onreadystatechange = function() {
        if (4 == g.readyState) {
          if (d)
            try {
              var a = g.responseText;
              if (1 > a.length) ge('xhr', 'ver', '0'), c();
              else if ('1' != a.charAt(0)) ge('xhr', 'ver', String(a.length)), c();
              else if (3 < d.count++) ge('xhr', 'tmr', '' + d.count), c();
              else if (1 == a.length) c();
              else {
                var b = a.charAt(1);
                if ('d' == b) pe('https://stats.g.doubleclick.net/j/collect', d.U, d, c);
                else if ('g' == b) {
                  var e = 'https://www.google.%/ads/ga-audiences'.replace('%', 'com');
                  wc(e, d.google, c);
                  var w = a.substring(2);
                  if (w)
                    if (/^[a-z.]{1,6}$/.test(w)) {
                      var ha = 'https://www.google.%/ads/ga-audiences'.replace('%', w);
                      wc(ha, d.google, ua);
                    } else ge('tld', 'bcc', w);
                } else ge('xhr', 'brc', b), c();
              }
            } catch (ue) {
              ge('xhr', 'rsp'), c();
            }
          else c();
          g = null;
        }
      };
      g.send(b);
      return !0;
    };
    var x = function(a, b, c) {
      return O.navigator.sendBeacon ? (O.navigator.sendBeacon(a, b) ? (c(), !0) : !1) : !1;
    };
    var ge = function (a, b, c) {
      100 * Math.random() >= 1 ||
        G('?') ||
        ((a = ['t=error', '_e=' + a, '_v=j73', 'sr=1']),
        b && a.push('_f=' + b),
        c && a.push('_m=' + K(c.substring(0, 100))),
        a.push('aip=1'),
        a.push('z=' + hd()),
        wc('https://www.google-analytics.com/u/d', a.join('&'), ua));
    };
  var h = function (a) {
    var b = (O.gaData = O.gaData || {});
    return (b[a] = b[a] || {});
  };
  var Ha = function () {
    this.M = [];
  };
  Ha.prototype.add = function (a) {
    this.M.push(a);
  };
  Ha.prototype.D = function (a) {
    try {
      for (var b = 0; b < this.M.length; b++) {
        var c = a.get(this.M[b]);
        c && ea(c) && c.call(O, a);
      }
    } catch (d) {}
    b = a.get(Ia);
    b != ua && ea(b) && (a.set(Ia, ua, !0), setTimeout(b, 10));
  };
  function Ja (a) {
    if (a.get(Ka) != 100 && La(P(a, Q)) % 1e4 >= 100 * R(a, Ka)) throw 'abort';
  }
  function Ma (a) {
    if (G(P(a, Na))) throw 'abort';
  }
  function Oa () {
    var a = M.location.protocol;
    if (a != 'http:' && a != 'https:') throw 'abort';
  }
  function Pa (a) {
    try {
      O.navigator.sendBeacon ? J(42) : O.XMLHttpRequest && 'withCredentials' in new O.XMLHttpRequest() && J(40);
    } catch (c) {}
    a.set(ld, Td(a), !0);
    a.set(Ac, R(a, Ac) + 1);
    var b = [];
    Qa.map(function (c, d) {
      d.F &&
        ((c = a.get(c)),
        void 0 != c && c != d.defaultValue && (typeof c == 'boolean' && (c *= 1), b.push(d.F + '=' + K('' + c))));
    });
    b.push('z=' + Bd());
    a.set(Ra, b.join('&'), !0);
  }
  function Sa (a) {
    var b = P(a, gd) || oe() + '/collect';
      var c = a.get(qe);
      var d = P(a, fa);
    !d && a.get(Vd) && (d = 'beacon');
    if (c) pe(b, P(a, Ra), c, a.get(Ia));
    else if (d) {
      c = d;
      d = P(a, Ra);
      var e = a.get(Ia);
      e = e || ua;
      c == 'image' ? wc(b, d, e) : (c == 'xhr' && wd(b, d, e)) || (c == 'beacon' && x(b, d, e)) || ba(b, d, e);
    } else ba(b, P(a, Ra), a.get(Ia));
    b = a.get(Na);
    b = h(b);
    c = b.hitcount;
    b.hitcount = c ? c + 1 : 1;
    b = a.get(Na);
    delete h(b).pending_experiments;
    a.set(Ia, ua, !0);
  }
  function Hc (a) {
    (O.gaData = O.gaData || {}).expId && a.set(Nc, (O.gaData = O.gaData || {}).expId);
    (O.gaData = O.gaData || {}).expVar && a.set(Oc, (O.gaData = O.gaData || {}).expVar);
    var b = a.get(Na);
    if ((b = h(b).pending_experiments)) {
      var c = [];
      for (d in b) b.hasOwnProperty(d) && b[d] && c.push(encodeURIComponent(d) + '.' + encodeURIComponent(b[d]));
      var d = c.join('!');
    } else d = void 0;
    d && a.set(m, d, !0);
  }
  function cd () {
    if (O.navigator && O.navigator.loadPurpose == 'preview') throw 'abort';
  }
  function yd (a) {
    var b = O.gaDevIds;
    ka(b) && b.length != 0 && a.set('&did', b.join(','), !0);
  }
  function vb (a) {
    if (!a.get(Na)) throw 'abort';
  }
  var hd = function () {
      return Math.round(2147483647 * Math.random());
    };
    var Bd = function () {
      try {
        var a = new Uint32Array(1);
        O.crypto.getRandomValues(a);
        return a[0] & 2147483647;
      } catch (b) {
        return hd();
      }
    };
  function Ta (a) {
    var b = R(a, Ua);
    b >= 500 && J(15);
    var c = P(a, Va);
    if (c != 'transaction' && c != 'item') {
      c = R(a, Wa);
      var d = new Date().getTime();
        var e = R(a, Xa);
      e == 0 && a.set(Xa, d);
      e = Math.round((2 * (d - e)) / 1e3);
      e > 0 && ((c = Math.min(c + e, 20)), a.set(Xa, d));
      if (c <= 0) throw 'abort';
      a.set(Wa, --c);
    }
    a.set(Ua, ++b);
  }
  var Ya = function () {
      this.data = new ee();
    };
    var Qa = new ee();
    var Za = [];
  Ya.prototype.get = function (a) {
    var b = $a(a);
      var c = this.data.get(a);
    b && void 0 == c && (c = ea(b.defaultValue) ? b.defaultValue() : b.defaultValue);
    return b && b.Z ? b.Z(this, a, c) : c;
  };
  var P = function (a, b) {
      a = a.get(b);
      return void 0 == a ? '' : '' + a;
    };
    var R = function (a, b) {
      a = a.get(b);
      return void 0 == a || a === '' ? 0 : 1 * a;
    };
  Ya.prototype.set = function (a, b, c) {
    if (a)
      {if ('object' == typeof a) for (var d in a) a.hasOwnProperty(d) && ab(this, d, a[d], c);
      else ab(this, a, b, c);}
  };
  var ab = function (a, b, c, d) {
      if (void 0 != c)
        {switch (b) {
          case Na:
            wb.test(c);
        }}
      var e = $a(b);
      e && e.o ? e.o(a, b, c, d) : a.data.set(b, c, d);
    };
    var bb = function(a, b, c, d, e) {
      this.name = a;
      this.F = b;
      this.Z = d;
      this.o = e;
      this.defaultValue = c;
    };
    var $a = function(a) {
      var b = Qa.get(a);
      if (!b)
        for (var c = 0; c < Za.length; c++) {
          var d = Za[c],
            e = d[0].exec(a);
          if (e) {
            b = d[1](e);
            Qa.set(b.name, b);
            break;
          }
        }
      return b;
    };
    var yc = function(a) {
      var b;
      Qa.map(function(c, d) {
        d.F == a && (b = d);
      });
      return b && b.name;
    };
    var S = function(a, b, c, d, e) {
      a = new bb(a, b, c, d, e);
      Qa.set(a.name, a);
      return a.name;
    };
    var cb = function(a, b) {
      Za.push([new RegExp('^' + a + '$'), b]);
    };
    var T = function(a, b, c) {
      return S(a, b, c, void 0, db);
    };
    var db = function () {};
  var gb = (qa(window.GoogleAnalyticsObject) && sa(window.GoogleAnalyticsObject)) || 'ga';
    var jd = /^(?:utma\.)?\d+\.\d+$/;
    var kd = /^amp-[\w.-]{22,64}$/;
    var Ba = !1;
    var hb = T('apiVersion', 'v');
    var ib = T('clientVersion', '_v');
  S('anonymizeIp', 'aip');
  var jb = S('adSenseId', 'a');
    var Va = S('hitType', 't');
    var Ia = S('hitCallback');
    var Ra = S('hitPayload');
  S('nonInteraction', 'ni');
  S('currencyCode', 'cu');
  S('dataSource', 'ds');
  var Vd = S('useBeacon', void 0, !1);
    var fa = S('transport');
  S('sessionControl', 'sc', '');
  S('sessionGroup', 'sg');
  S('queueTime', 'qt');
  var Ac = S('_s', '_s');
  S('screenName', 'cd');
  var kb = S('location', 'dl', '');
    var lb = S('referrer', 'dr');
    var mb = S('page', 'dp', '');
  S('hostname', 'dh');
  var nb = S('language', 'ul');
    var ob = S('encoding', 'de');
  S('title', 'dt', function () {
    return M.title || void 0;
  });
  cb('contentGroup([0-9]+)', function (a) {
    return new bb(a[0], 'cg' + a[1]);
  });
  var pb = S('screenColors', 'sd');
    var qb = S('screenResolution', 'sr');
    var rb = S('viewportSize', 'vp');
    var sb = S('javaEnabled', 'je');
    var tb = S('flashVersion', 'fl');
  S('campaignId', 'ci');
  S('campaignName', 'cn');
  S('campaignSource', 'cs');
  S('campaignMedium', 'cm');
  S('campaignKeyword', 'ck');
  S('campaignContent', 'cc');
  var ub = S('eventCategory', 'ec');
    var xb = S('eventAction', 'ea');
    var yb = S('eventLabel', 'el');
    var zb = S('eventValue', 'ev');
    var Bb = S('socialNetwork', 'sn');
    var Cb = S('socialAction', 'sa');
    var Db = S('socialTarget', 'st');
    var Eb = S('l1', 'plt');
    var Fb = S('l2', 'pdt');
    var Gb = S('l3', 'dns');
    var Hb = S('l4', 'rrt');
    var Ib = S('l5', 'srt');
    var Jb = S('l6', 'tcp');
    var Kb = S('l7', 'dit');
    var Lb = S('l8', 'clt');
    var Ve = S('l9', '_gst');
    var We = S('l10', '_gbt');
    var Xe = S('l11', '_cst');
    var Ye = S('l12', '_cbt');
    var Mb = S('timingCategory', 'utc');
    var Nb = S('timingVar', 'utv');
    var Ob = S('timingLabel', 'utl');
    var Pb = S('timingValue', 'utt');
  S('appName', 'an');
  S('appVersion', 'av', '');
  S('appId', 'aid', '');
  S('appInstallerId', 'aiid', '');
  S('exDescription', 'exd');
  S('exFatal', 'exf');
  var Nc = S('expId', 'xid');
    var Oc = S('expVar', 'xvar');
    var m = S('exp', 'exp');
    var Rc = S('_utma', '_utma');
    var Sc = S('_utmz', '_utmz');
    var Tc = S('_utmht', '_utmht');
    var Ua = S('_hc', void 0, 0);
    var Xa = S('_ti', void 0, 0);
    var Wa = S('_to', void 0, 20);
  cb('dimension([0-9]+)', function (a) {
    return new bb(a[0], 'cd' + a[1]);
  });
  cb('metric([0-9]+)', function (a) {
    return new bb(a[0], 'cm' + a[1]);
  });
  S('linkerParam', void 0, void 0, Bc, db);
  var Ze = T('_cd2l', void 0, !1);
    var ld = S('usage', '_u');
    var Gd = S('_um');
  S(
    'forceSSL',
    void 0,
    void 0,
    function () {
      return Ba;
    },
    function (a, b, c) {
      J(34);
      Ba = !!c;
    },
  );
  var ed = S('_j1', 'jid');
    var ia = S('_j2', 'gjid');
  cb('\\&(.*)', function (a) {
    var b = new bb(a[0], a[1]);
      var c = yc(a[0].substring(1));
    c &&
      ((b.Z = function (a) {
        return a.get(c);
      }),
      (b.o = function (a, b, g, ca) {
        a.set(c, g, ca);
      }),
      (b.F = void 0));
    return b;
  });
  var Qb = T('_oot');
    var dd = S('previewTask');
    var Rb = S('checkProtocolTask');
    var md = S('validationTask');
    var Sb = S('checkStorageTask');
    var Uc = S('historyImportTask');
    var Tb = S('samplerTask');
    var Vb = S('_rlt');
    var Wb = S('buildHitTask');
    var Xb = S('sendHitTask');
    var Vc = S('ceTask');
    var zd = S('devIdTask');
    var Cd = S('timingTask');
    var Ld = S('displayFeaturesTask');
    var oa = S('customTask');
    var V = T('name');
    var Q = T('clientId', 'cid');
    var n = T('clientIdTime');
    var xd = T('storedClientId');
    var Ad = S('userId', 'uid');
    var Na = T('trackingId', 'tid');
    var U = T('cookieName', void 0, '_ga');
    var W = T('cookieDomain');
    var Yb = T('cookiePath', void 0, '/');
    var Zb = T('cookieExpires', void 0, 63072e3);
    var Hd = T('cookieUpdate', void 0, !0);
    var $b = T('legacyCookieDomain');
    var Wc = T('legacyHistoryImport', void 0, !0);
    var ac = T('storage', void 0, 'cookie');
    var bc = T('allowLinker', void 0, !1);
    var cc = T('allowAnchor', void 0, !0);
    var Ka = T('sampleRate', 'sf', 100);
    var dc = T('siteSpeedSampleRate', void 0, 1);
    var ec = T('alwaysSendReferrer', void 0, !1);
    var I = T('_gid', '_gid');
    var la = T('_gcn');
    var Kd = T('useAmpClientId');
    var ce = T('_gclid');
    var fe = T('_gt');
    var he = T('_ge', void 0, 7776e6);
    var ie = T('_gclsrc');
    var je = T('storeGac', void 0, !0);
    var gd = S('transportUrl');
    var Md = S('_r', '_r');
    var qe = S('_dp');
    var Ud = S('allowAdFeatures', void 0, !0);
  function X (a, b, c, d) {
    b[a] = function () {
      try {
        return d && J(d), c.apply(this, arguments);
      } catch (e) {
        throw (ge('exc', a, e && e.name), e);
      }
    };
  }
  var Od = function () {
      this.V = 100;
      this.$ = this.fa = !1;
      this.oa = 'detourexp';
      this.groups = 1;
    };
    var Ed = function (a) {
      var b = new Od();
        var c;
      if (b.fa && b.$) return 0;
      b.$ = !0;
      if (a) {
        if (b.oa && void 0 !== a.get(b.oa)) return R(a, b.oa);
        if (a.get(dc) == 0) return 0;
      }
      if (b.V == 0) return 0;
      void 0 === c && (c = Bd());
      return c % b.V == 0 ? (Math.floor(c / b.V) % b.groups) + 1 : 0;
    };
  function fc () {
    var a, b;
    if ((b = (b = O.navigator) ? b.plugins : null) && b.length)
      {for (var c = 0; c < b.length && !a; c++) {
        var d = b[c];
        -1 < d.name.indexOf('Shockwave Flash') && (a = d.description);
      }}
    if (!a)
      {try {
        var e = new ActiveXObject('ShockwaveFlash.ShockwaveFlash.7');
        a = e.GetVariable('$version');
      } catch (g) {}}
    if (!a)
      {try {
        (e = new ActiveXObject('ShockwaveFlash.ShockwaveFlash.6')),
          (a = 'WIN 6,0,21,0'),
          (e.AllowScriptAccess = 'always'),
          (a = e.GetVariable('$version'));
      } catch (g) {}}
    if (!a)
      {try {
        (e = new ActiveXObject('ShockwaveFlash.ShockwaveFlash')), (a = e.GetVariable('$version'));
      } catch (g) {}}
    a && (e = a.match(/[\d]+/g)) && e.length >= 3 && (a = e[0] + '.' + e[1] + ' r' + e[2]);
    return a || void 0;
  }
  var aa = function (a) {
      var b = Math.min(R(a, dc), 100);
      return La(P(a, Q)) % 100 >= b ? !1 : !0;
    };
    var gc = function(a) {
      var b = {};
      if (Ec(b) || Fc(b)) {
        var c = b[Eb];
        void 0 == c ||
          Infinity == c ||
          isNaN(c) ||
          (0 < c
            ? (Y(b, Gb),
              Y(b, Jb),
              Y(b, Ib),
              Y(b, Fb),
              Y(b, Hb),
              Y(b, Kb),
              Y(b, Lb),
              Y(b, Ve),
              Y(b, We),
              Y(b, Xe),
              Y(b, Ye),
              va(function() {
                a(b);
              }, 10))
            : L(
                O,
                'load',
                function() {
                  gc(a);
                },
                !1,
              ));
      }
    };
    var Ec = function(a) {
      var b = O.performance || O.webkitPerformance;
      b = b && b.timing;
      if (!b) return !1;
      var c = b.navigationStart;
      if (0 == c) return !1;
      a[Eb] = b.loadEventStart - c;
      a[Gb] = b.domainLookupEnd - b.domainLookupStart;
      a[Jb] = b.connectEnd - b.connectStart;
      a[Ib] = b.responseStart - b.requestStart;
      a[Fb] = b.responseEnd - b.responseStart;
      a[Hb] = b.fetchStart - c;
      a[Kb] = b.domInteractive - c;
      a[Lb] = b.domContentLoadedEventStart - c;
      a[Ve] = N.L - c;
      a[We] = N.ya - c;
      O.google_tag_manager &&
        O.google_tag_manager._li &&
        ((b = O.google_tag_manager._li), (a[Xe] = b.cst), (a[Ye] = b.cbt));
      return !0;
    };
    var Fc = function(a) {
      if (O.top != O) return !1;
      var b = O.external,
        c = b && b.onloadT;
      b && !b.isValidLoadTime && (c = void 0);
      2147483648 < c && (c = void 0);
      0 < c && b.setPageReadyTime();
      if (void 0 == c) return !1;
      a[Eb] = c;
      return !0;
    };
    var Y = function(a, b) {
      var c = a[b];
      if (isNaN(c) || Infinity == c || 0 > c) a[b] = void 0;
    };
    var Fd = function (a) {
      return function (b) {
        if (b.get(Va) == 'pageview' && !a.I) {
          a.I = !0;
          var c = aa(b);
            var d = E(b.get(kb), 'gclid').length > 0;
          (c || d) &&
            gc(function (b) {
              c && a.send('timing', b);
              d && a.send('adtiming', b);
            });
        }
      };
    };
  var hc = !1;
    var mc = function(a) {
      if ('cookie' == P(a, ac)) {
        if (a.get(Hd) || P(a, xd) != P(a, Q)) {
          var b = 1e3 * R(a, Zb);
          ma(a, Q, U, b);
        }
        ma(a, I, la, 864e5);
        if (a.get(je)) {
          var c = a.get(ce);
          if (c) {
            var d = Math.min(R(a, he), 1e3 * R(a, Zb));
            d = Math.min(d, 1e3 * R(a, fe) + d - new Date().getTime());
            a.data.set(he, d);
            b = {};
            var e = a.get(fe),
              g = a.get(ie),
              ca = kc(P(a, Yb)),
              l = lc(P(a, W));
            a = P(a, Na);
            g && 'aw.ds' != g
              ? b && (b.ua = !0)
              : ((c = ['1', e, Cc(c)].join('.')), 0 < d && (b && (b.ta = !0), zc('_gac_' + Cc(a), c, ca, l, a, d)));
            le(b);
          }
        } else J(75);
      }
    };
    var ma = function(a, b, c, d) {
      var e = nd(a, b);
      if (e) {
        c = P(a, c);
        var g = kc(P(a, Yb)),
          ca = lc(P(a, W)),
          l = P(a, Na);
        if ('auto' != ca) zc(c, e, g, ca, l, d) && (hc = !0);
        else {
          J(32);
          for (var k = id(), w = 0; w < k.length; w++)
            if (((ca = k[w]), a.data.set(W, ca), (e = nd(a, b)), zc(c, e, g, ca, l, d))) {
              hc = !0;
              return;
            }
          a.data.set(W, 'auto');
        }
      }
    };
    var nc = function(a) {
      if ('cookie' == P(a, ac) && !hc && (mc(a), !hc)) throw 'abort';
    };
    var Yc = function(a) {
      if (a.get(Wc)) {
        var b = P(a, W),
          c = P(a, $b) || xa(),
          d = Xc('__utma', c, b);
        d &&
          (J(19),
          a.set(Tc, new Date().getTime(), !0),
          a.set(Rc, d.R),
          (b = Xc('__utmz', c, b)) && d.hash == b.hash && a.set(Sc, b.R));
      }
    };
    var nd = function(a, b) {
      b = Cc(P(a, b));
      var c = lc(P(a, W)).split('.').length;
      a = jc(P(a, Yb));
      1 < a && (c += '-' + a);
      return b ? ['GA1', c, b].join('.') : '';
    };
    var Xd = function(a, b) {
      return na(b, P(a, W), P(a, Yb));
    };
    var na = function(a, b, c) {
      if (!a || 1 > a.length) J(12);
      else {
        for (var d = [], e = 0; e < a.length; e++) {
          var g = a[e];
          var ca = g.split('.');
          var l = ca.shift();
          ('GA1' == l || '1' == l) && 1 < ca.length
            ? ((g = ca.shift().split('-')),
              1 == g.length && (g[1] = '1'),
              (g[0] *= 1),
              (g[1] *= 1),
              (ca = { H: g, s: ca.join('.') }))
            : (ca = kd.test(g) ? { H: [0, 0], s: g } : void 0);
          ca && d.push(ca);
        }
        if (1 == d.length) return J(13), d[0].s;
        if (0 == d.length) J(12);
        else {
          J(14);
          d = Gc(d, lc(b).split('.').length, 0);
          if (1 == d.length) return d[0].s;
          d = Gc(d, jc(c), 1);
          1 < d.length && J(41);
          return d[0] && d[0].s;
        }
      }
    };
    var Gc = function(a, b, c) {
      for (var d = [], e = [], g, ca = 0; ca < a.length; ca++) {
        var l = a[ca];
        l.H[c] == b ? d.push(l) : void 0 == g || l.H[c] < g ? ((e = [l]), (g = l.H[c])) : l.H[c] == g && e.push(l);
      }
      return 0 < d.length ? d : e;
    };
    var lc = function(a) {
      return 0 == a.indexOf('.') ? a.substr(1) : a;
    };
    var id = function() {
      var a = [],
        b = xa().split('.');
      if (4 == b.length) {
        var c = b[b.length - 1];
        if (parseInt(c, 10) == c) return ['none'];
      }
      for (c = b.length - 2; 0 <= c; c--) a.push(b.slice(c).join('.'));
      b = M.location.hostname;
      eb.test(b) || vc.test(b) || a.push('none');
      return a;
    };
    var kc = function(a) {
      if (!a) return '/';
      1 < a.length && a.lastIndexOf('/') == a.length - 1 && (a = a.substr(0, a.length - 1));
      0 != a.indexOf('/') && (a = '/' + a);
      return a;
    };
    var jc = function(a) {
      a = kc(a);
      return '/' == a ? 1 : a.split('/').length;
    };
    var le = function (a) {
      a.ta && J(77);
      a.na && J(74);
      a.pa && J(73);
      a.ua && J(69);
    };
  function Xc (a, b, c) {
    b == 'none' && (b = '');
    var d = [];
      var e = Ca(a);
    a = a == '__utma' ? 6 : 2;
    for (var g = 0; g < e.length; g++) {
      var ca = ('' + e[g]).split('.');
      ca.length >= a && d.push({ hash: ca[0], R: e[g], O: ca });
    }
    if (d.length != 0) return d.length == 1 ? d[0] : Zc(b, d) || Zc(c, d) || Zc(null, d) || d[0];
  }
  function Zc (a, b) {
    if (a == null) var c = (a = 1);
    else (c = La(a)), (a = La(D(a, '.') ? a.substring(1) : '.' + a));
    for (var d = 0; d < b.length; d++) if (b[d].hash == c || b[d].hash == a) return b[d];
  }
  var od = new RegExp(/^https?:\/\/([^\/:]+)/);
    var De = O.google_tag_data.glBridge;
    var pd = /(.*)([?&#])(?:_ga=[^&#]*)(?:&?)(.*)/;
    var me = /(.*)([?&#])(?:_gac=[^&#]*)(?:&?)(.*)/;
  function Bc (a) {
    if (a.get(Ze)) return J(35), De.generate($e(a));
    var b = a.get(Q);
      var c = a.get(I) || '';
    b = '_ga=2.' + K(pa(c + b, 0) + '.' + c + '-' + b);
    (a = af(a)) ? (J(44), (a = '&_gac=1.' + K([pa(a.qa, 0), a.timestamp, a.qa].join('.')))) : (a = '');
    return b + a;
  }
  function Ic (a, b) {
    var c = new Date();
      var d = O.navigator;
      var e = d.plugins || [];
    a = [a, d.userAgent, c.getTimezoneOffset(), c.getYear(), c.getDate(), c.getHours(), c.getMinutes() + b];
    for (b = 0; b < e.length; ++b) a.push(e[b].description);
    return La(a.join('.'));
  }
  function pa (a, b) {
    var c = new Date();
      var d = O.navigator;
      var e = c.getHours() + Math.floor((c.getMinutes() + b) / 60);
    return La(
      [
        a,
        d.userAgent,
        d.language || '',
        c.getTimezoneOffset(),
        c.getYear(),
        c.getDate() + Math.floor(e / 24),
        (24 + e) % 24,
        (60 + c.getMinutes() + b) % 60,
      ].join('.'),
    );
  }
  var Dc = function (a) {
    J(48);
    this.target = a;
    this.T = !1;
  };
  Dc.prototype.ca = function (a, b) {
    if (a) {
      if (this.target.get(Ze)) return De.decorate($e(this.target), a, b);
      if (a.tagName) {
        if (a.tagName.toLowerCase() == 'a') {
          a.href && (a.href = qd(this, a.href, b));
          return;
        }
        if (a.tagName.toLowerCase() == 'form') return rd(this, a);
      }
      if (typeof a == 'string') return qd(this, a, b);
    }
  };
  var qd = function (a, b, c) {
      var d = pd.exec(b);
      d && d.length >= 3 && (b = d[1] + (d[3] ? d[2] + d[3] : ''));
      (d = me.exec(b)) && d.length >= 3 && (b = d[1] + (d[3] ? d[2] + d[3] : ''));
      a = a.target.get('linkerParam');
      var e = b.indexOf('?');
      d = b.indexOf('#');
      c
        ? (b += (d == -1 ? '#' : '&') + a)
        : ((c = e == -1 ? '?' : '&'), (b = d == -1 ? b + (c + a) : b.substring(0, d) + c + a + b.substring(d)));
      b = b.replace(/&+_ga=/, '&_ga=');
      return (b = b.replace(/&+_gac=/, '&_gac='));
    };
    var rd = function (a, b) {
      if (b && b.action)
        {if ('get' == b.method.toLowerCase()) {
          a = a.target.get('linkerParam').split('&');
          for (var c = 0; c < a.length; c++) {
            var d = a[c].split('='),
              e = d[1];
            d = d[0];
            for (var g = b.childNodes || [], ca = !1, l = 0; l < g.length; l++)
              if (g[l].name == d) {
                g[l].setAttribute('value', e);
                ca = !0;
                break;
              }
            ca ||
              ((g = M.createElement('input')),
              g.setAttribute('type', 'hidden'),
              g.setAttribute('name', d),
              g.setAttribute('value', e),
              b.appendChild(g));
          }
        } else 'post' == b.method.toLowerCase() && (b.action = qd(a, b.action));}
    };
  Dc.prototype.S = function (a, b, c) {
    function d (c) {
      try {
        c = c || O.event;
        a: {
          var d = c.target || c.srcElement;
          for (c = 100; d && c > 0;) {
            if (d.href && d.nodeName.match(/^a(?:rea)?$/i)) {
              var g = d;
              break a;
            }
            d = d.parentNode;
            c--;
          }
          g = {};
        }
        (g.protocol == 'http:' || g.protocol == 'https:') &&
          sd(a, g.hostname || '') &&
          g.href &&
          (g.href = qd(e, g.href, b));
      } catch (k) {
        J(26);
      }
    }
    var e = this;
    this.target.get(Ze)
      ? De.auto(
        function () {
          return $e(e.target);
        },
        a,
        b ? 'fragment' : '',
        c,
      )
      : (this.T || ((this.T = !0), L(M, 'mousedown', d, !1), L(M, 'keyup', d, !1)),
      c &&
          L(M, 'submit', function (b) {
            b = b || O.event;
            if ((b = b.target || b.srcElement) && b.action) {
              var c = b.action.match(od);
              c && sd(a, c[1]) && rd(e, b);
            }
          }));
  };
  function sd (a, b) {
    if (b == M.location.hostname) return !1;
    for (var c = 0; c < a.length; c++)
      {if (a[c] instanceof RegExp) {
        if (a[c].test(b)) return !0;
      } else if (0 <= b.indexOf(a[c])) return !0;}
    return !1;
  }
  function ke (a, b) {
    return b != Ic(a, 0) && b != Ic(a, -1) && b != Ic(a, -2) && b != pa(a, 0) && b != pa(a, -1) && b != pa(a, -2);
  }
  function $e (a) {
    var b = af(a);
    return { _ga: a.get(Q), _gid: a.get(I) || void 0, _gac: b ? [b.qa, b.timestamp].join('.') : void 0 };
  }
  function af (a) {
    function b (a) {
      return void 0 == a || a === '' ? 0 : Number(a);
    }
    var c = a.get(ce);
    if (c && a.get(je)) {
      var d = b(a.get(fe));
      if (1e3 * d + b(a.get(he)) <= new Date().getTime()) J(76);
      else return { timestamp: d, qa: c };
    }
  }
  var p = /^(GTM|OPT)-[A-Z0-9]+$/;
    var q = /;_gaexp=[^;]*/g;
    var r = /;((__utma=)|([^;=]+=GAX?\d+\.))[^;]*/g;
    var Aa = /^https?:\/\/[\w\-.]+\.google.com(:\d+)?\/optimize\/opt-launch\.html\?.*$/;
    var t = function (a) {
      function b (a, b) {
        b && (c += '&' + a + '=' + K(b));
      }
      var c = 'https://www.google-analytics.com/gtm/js?id=' + K(a.id);
      a.B != 'dataLayer' && b('l', a.B);
      b('t', a.target);
      b('cid', a.clientId);
      b('cidt', a.ka);
      b('gac', a.la);
      b('aip', a.ia);
      a.sync && b('m', 'sync');
      b('cycle', a.G);
      a.qa && b('gclid', a.qa);
      Aa.test(M.referrer) && b('cb', String(hd()));
      return c;
    };
  var Jd = function (a, b, c) {
      this.aa = b;
      (b = c) || (b = (b = P(a, V)) && b != 't0' ? (Wd.test(b) ? '_gat_' + Cc(P(a, Na)) : '_gat_' + Cc(b)) : '_gat');
      this.Y = b;
      this.ra = null;
    };
    var Rd = function(a, b) {
      var c = b.get(Wb);
      b.set(Wb, function(b) {
        Pd(a, b, ed);
        Pd(a, b, ia);
        var d = c(b);
        Qd(a, b);
        return d;
      });
      var d = b.get(Xb);
      b.set(Xb, function(b) {
        var c = d(b);
        if (se(b)) {
          if (ne() !== H(a, b)) {
            J(80);
            var e = { U: re(a, b, 1), google: re(a, b, 2), count: 0 };
            pe('https://stats.g.doubleclick.net/j/collect', e.U, e);
          } else ta(re(a, b, 0));
          b.set(ed, '', !0);
        }
        return c;
      });
    };
    var Pd = function(a, b, c) {
      !1 === b.get(Ud) || b.get(c) || ('1' == Ca(a.Y)[0] ? b.set(c, '', !0) : b.set(c, '' + hd(), !0));
    };
    var Qd = function(a, b) {
      se(b) && zc(a.Y, '1', b.get(Yb), b.get(W), b.get(Na), 6e4);
    };
    var se = function(a) {
      return !!a.get(ed) && a.get(Ud);
    };
    var re = function(a, b, c) {
      var d = new ee(),
        e = function(a) {
          $a(a).F && d.set($a(a).F, b.get(a));
        };
      e(hb);
      e(ib);
      e(Na);
      e(Q);
      e(ed);
      if (0 == c || 1 == c) e(Ad), e(ia), e(I);
      d.set($a(ld).F, Td(b));
      var g = '';
      d.map(function(a, b) {
        g += K(a) + '=';
        g += K('' + b) + '&';
      });
      g += 'z=' + hd();
      0 == c ? (g = a.aa + g) : 1 == c ? (g = 't=dc&aip=1&_r=3&' + g) : 2 == c && (g = 't=sr&aip=1&_r=4&slf_rd=1&' + g);
      return g;
    };
    var H = function(a, b) {
      null === a.ra && ((a.ra = 1 === Ed(b)), a.ra && J(33));
      return a.ra;
    };
    var Wd = /^gtm\d+$/;
  var fd = function (a, b) {
    a = a.b;
    if (!a.get('dcLoaded')) {
      var c = new $c(Dd(a));
      c.set(29);
      a.set(Gd, c.w);
      b = b || {};
      var d;
      b[U] && (d = Cc(b[U]));
      b = new Jd(a, 'https://stats.g.doubleclick.net/r/collect?t=dc&aip=1&_r=3&', d);
      Rd(b, a);
      a.set('dcLoaded', !0);
    }
  };
  var Sd = function (a) {
    if (!a.get('dcLoaded') && a.get(ac) == 'cookie') {
      var b = new Jd(a);
      Pd(b, a, ed);
      Pd(b, a, ia);
      Qd(b, a);
      if (se(a)) {
        var c = ne() !== H(b, a);
        a.set(Md, 1, !0);
        c
          ? (J(79),
          a.set(gd, oe() + '/j/collect', !0),
          a.set(qe, { U: re(b, a, 1), google: re(b, a, 2), count: 0 }, !0))
          : a.set(gd, oe() + '/r/collect', !0);
      }
    }
  };
  var Lc = function () {
    var a = (O.gaGlobal = O.gaGlobal || {});
    return (a.hid = a.hid || hd());
  };
  var ad;
    var bd = function (a, b, c) {
      if (!ad) {
        var d = M.location.hash;
        var e = O.name;
          var g = /^#?gaso=([^&]*)/;
        if (
          (e =
            (d = (d = (d && d.match(g)) || (e && e.match(g))) ? d[1] : Ca('GASO')[0] || '') &&
            d.match(/^(?:!([-0-9a-z.]{1,40})!)?([-.\w]{10,1200})$/i))
        )
          {zc('GASO', '' + d, c, b, a, 0),
            window._udo || (window._udo = b),
            window._utcp || (window._utcp = c),
            (a = e[1]),
            wa(
              'https://www.google.com/analytics/web/inpage/pub/inpage.js?' + (a ? 'prefix=' + a + '&' : '') + hd(),
              '_gasojs',
            );}
        ad = !0;
      }
    };
  var wb = /^(UA|YT|MO|GP)-(\d+)-(\d+)$/;
    var pc = function(a) {
      function b(a, b) {
        d.b.data.set(a, b);
      }
      function c(a, c) {
        b(a, c);
        d.filters.add(a);
      }
      var d = this;
      this.b = new Ya();
      this.filters = new Ha();
      b(V, a[V]);
      b(Na, sa(a[Na]));
      b(U, a[U]);
      b(W, a[W] || xa());
      b(Yb, a[Yb]);
      b(Zb, a[Zb]);
      b(Hd, a[Hd]);
      b($b, a[$b]);
      b(Wc, a[Wc]);
      b(bc, a[bc]);
      b(cc, a[cc]);
      b(Ka, a[Ka]);
      b(dc, a[dc]);
      b(ec, a[ec]);
      b(ac, a[ac]);
      b(Ad, a[Ad]);
      b(n, a[n]);
      b(Kd, a[Kd]);
      b(je, a[je]);
      b(Ze, a[Ze]);
      b(hb, 1);
      b(ib, 'j73');
      c(Qb, Ma);
      c(oa, ua);
      c(dd, cd);
      c(Rb, Oa);
      c(md, vb);
      c(Sb, nc);
      c(Uc, Yc);
      c(Tb, Ja);
      c(Vb, Ta);
      c(Vc, Hc);
      c(zd, yd);
      c(Ld, Sd);
      c(Wb, Pa);
      c(Xb, Sa);
      c(Cd, Fd(this));
      Kc(this.b);
      Jc(this.b, a[Q]);
      this.b.set(jb, Lc());
      bd(this.b.get(Na), this.b.get(W), this.b.get(Yb));
    };
    var Jc = function(a, b) {
      var c = P(a, U);
      a.data.set(la, '_ga' == c ? '_gid' : c + '_gid');
      if ('cookie' == P(a, ac)) {
        hc = !1;
        c = Ca(P(a, U));
        c = Xd(a, c);
        if (!c) {
          c = P(a, W);
          var d = P(a, $b) || xa();
          c = Xc('__utma', d, c);
          void 0 != c ? (J(10), (c = c.O[1] + '.' + c.O[2])) : (c = void 0);
        }
        c && (hc = !0);
        if ((d = c && !a.get(Hd)))
          if (((d = c.split('.')), 2 != d.length)) d = !1;
          else if ((d = Number(d[1]))) {
            var e = R(a, Zb);
            d = d + e < new Date().getTime() / 1e3;
          } else d = !1;
        d && (c = void 0);
        c && (a.data.set(xd, c), a.data.set(Q, c), (c = Ca(P(a, la))), (c = Xd(a, c)) && a.data.set(I, c));
        if (a.get(je) && ((c = a.get(ce)), (d = a.get(ie)), !c || (d && 'aw.ds' != d))) {
          c = {};
          if (M) {
            d = [];
            e = M.cookie.split(';');
            for (var g = /^\s*_gac_(UA-\d+-\d+)=\s*(.+?)\s*$/, ca = 0; ca < e.length; ca++) {
              var l = e[ca].match(g);
              l && d.push({ ja: l[1], value: l[2] });
            }
            e = {};
            if (d && d.length)
              for (g = 0; g < d.length; g++)
                ((ca = d[g].value.split('.')), '1' != ca[0] || 3 != ca.length)
                  ? c && (c.na = !0)
                  : ca[1] &&
                    (e[d[g].ja] ? c && (c.pa = !0) : (e[d[g].ja] = []),
                    e[d[g].ja].push({ timestamp: ca[1], qa: ca[2] }));
            d = e;
          } else d = {};
          d = d[P(a, Na)];
          le(c);
          d && 0 != d.length && ((c = d[0]), a.data.set(fe, c.timestamp), a.data.set(ce, c.qa));
        }
      }
      if (
        a.get(Hd) &&
        ((c = be('_ga', a.get(cc))),
        (d = be('_gl', a.get(cc))),
        (e = De.get(a.get(cc))),
        (g = e._ga),
        d && 0 < d.indexOf('_ga') && !g && J(30),
        c || g)
      )
        if ((c && g && J(36), a.get(bc))) {
          if (
            (g &&
              (J(38),
              a.data.set(Q, g),
              e._gid && (J(51), a.data.set(I, e._gid)),
              e._gac &&
                (d = e._gac.split('.')) &&
                2 == d.length &&
                (J(37), a.data.set(ce, d[0]), a.data.set(fe, d[1]))),
            c)
          )
            b: if (((d = c.indexOf('.')), -1 == d)) J(22);
            else {
              e = c.substring(0, d);
              g = c.substring(d + 1);
              d = g.indexOf('.');
              c = g.substring(0, d);
              g = g.substring(d + 1);
              if ('1' == e) {
                if (((d = g), ke(d, c))) {
                  J(23);
                  break b;
                }
              } else if ('2' == e) {
                d = g.indexOf('-');
                e = '';
                0 < d ? ((e = g.substring(0, d)), (d = g.substring(d + 1))) : (d = g.substring(1));
                if (ke(e + d, c)) {
                  J(53);
                  break b;
                }
                e && (J(2), a.data.set(I, e));
              } else {
                J(22);
                break b;
              }
              J(11);
              a.data.set(Q, d);
              if ((c = be('_gac', a.get(cc))))
                (c = c.split('.')),
                  '1' != c[0] || 4 != c.length
                    ? J(72)
                    : ke(c[3], c[1])
                    ? J(71)
                    : (a.data.set(ce, c[3]), a.data.set(fe, c[2]), J(70));
            }
        } else J(21);
      b && (J(9), a.data.set(Q, K(b)));
      a.get(Q) ||
        ((b = (b = O.gaGlobal && O.gaGlobal.vid) && -1 != b.search(jd) ? b : void 0)
          ? (J(17), a.data.set(Q, b))
          : (J(8), a.data.set(Q, ra())));
      a.get(I) || (J(3), a.data.set(I, ra()));
      mc(a);
    };
    var Kc = function (a) {
      var b = O.navigator;
        var c = O.screen;
        var d = M.location;
      a.set(lb, ya(a.get(ec), a.get(Kd)));
      if (d) {
        var e = d.pathname || '';
        e.charAt(0) != '/' && (J(31), (e = '/' + e));
        a.set(kb, d.protocol + '//' + d.hostname + e + d.search);
      }
      c && a.set(qb, c.width + 'x' + c.height);
      c && a.set(pb, c.colorDepth + '-bit');
      c = M.documentElement;
      var g = (e = M.body) && e.clientWidth && e.clientHeight;
        var ca = [];
      c && c.clientWidth && c.clientHeight && (M.compatMode === 'CSS1Compat' || !g)
        ? (ca = [c.clientWidth, c.clientHeight])
        : g && (ca = [e.clientWidth, e.clientHeight]);
      c = ca[0] <= 0 || ca[1] <= 0 ? '' : ca.join('x');
      a.set(rb, c);
      a.set(tb, fc());
      a.set(ob, M.characterSet || M.charset);
      a.set(sb, (b && typeof b.javaEnabled === 'function' && b.javaEnabled()) || !1);
      a.set(nb, ((b && (b.language || b.browserLanguage)) || '').toLowerCase());
      a.data.set(ce, be('gclid', !0));
      a.data.set(ie, be('gclsrc', !0));
      a.data.set(fe, Math.round(new Date().getTime() / 1e3));
      if (d && a.get(cc) && (b = M.location.hash)) {
        b = b.split(/[?&#]+/);
        d = [];
        for (c = 0; c < b.length; ++c)
          {(D(b[c], 'utm_id') ||
            D(b[c], 'utm_campaign') ||
            D(b[c], 'utm_source') ||
            D(b[c], 'utm_medium') ||
            D(b[c], 'utm_term') ||
            D(b[c], 'utm_content') ||
            D(b[c], 'gclid') ||
            D(b[c], 'dclid') ||
            D(b[c], 'gclsrc')) &&
            d.push(b[c]);}
        d.length > 0 && ((b = '#' + d.join('&')), a.set(kb, a.get(kb) + b));
      }
    };
  pc.prototype.get = function (a) {
    return this.b.get(a);
  };
  pc.prototype.set = function (a, b) {
    this.b.set(a, b);
  };
  var qc = { pageview: [mb], event: [ub, xb, yb, zb], social: [Bb, Cb, Db], timing: [Mb, Nb, Pb, Ob] };
  pc.prototype.send = function (a) {
    if (!(arguments.length < 1)) {
      if (typeof arguments[0] === 'string') {
        var b = arguments[0];
        var c = [].slice.call(arguments, 1);
      } else (b = arguments[0] && arguments[0][Va]), (c = arguments);
      b &&
        ((c = za(qc[b] || [], c)),
        (c[Va] = b),
        this.b.set(c, void 0, !0),
        this.filters.D(this.b),
        (this.b.data.m = {}));
    }
  };
  pc.prototype.ma = function (a, b) {
    var c = this;
    u(a, c, b) ||
      (v(a, function () {
        u(a, c, b);
      }),
      y(String(c.get(V)), a, void 0, b, !0));
  };
  var rc = function (a) {
      if (M.visibilityState == 'prerender') return !1;
      a();
      return !0;
    };
    var z = function (a) {
      if (!rc(a)) {
        J(16);
        var b = !1;
          var c = function () {
            if (!b && rc(a)) {
              b = !0;
              var d = c;
                var e = M;
              e.removeEventListener
                ? e.removeEventListener('visibilitychange', d, !1)
                : e.detachEvent && e.detachEvent('onvisibilitychange', d);
            }
          };
        L(M, 'visibilitychange', c);
      }
    };
  var td = /^(?:(\w+)\.)?(?:(\w+):)?(\w+)$/;
    var sc = function (a) {
      if (ea(a[0])) this.u = a[0];
      else {
        var b = td.exec(a[0]);
        b != null &&
          b.length == 4 &&
          ((this.c = b[1] || 't0'),
          (this.K = b[2] || ''),
          (this.C = b[3]),
          (this.a = [].slice.call(a, 1)),
          this.K ||
            ((this.A = this.C == 'create'),
            (this.i = this.C == 'require'),
            (this.g = this.C == 'provide'),
            (this.ba = this.C == 'remove')),
          this.i &&
            (this.a.length >= 3
              ? ((this.X = this.a[1]), (this.W = this.a[2]))
              : this.a[1] && (qa(this.a[1]) ? (this.X = this.a[1]) : (this.W = this.a[1]))));
        b = a[1];
        a = a[2];
        if (!this.C) throw 'abort';
        if (this.i && (!qa(b) || b == '')) throw 'abort';
        if (this.g && (!qa(b) || b == '' || !ea(a))) throw 'abort';
        if (ud(this.c) || ud(this.K)) throw 'abort';
        if (this.g && this.c != 't0') throw 'abort';
      }
    };
  function ud (a) {
    return a.indexOf('.') >= 0 || a.indexOf(':') >= 0;
  }
  var Yd, Zd, $d, A;
  Yd = new ee();
  $d = new ee();
  A = new ee();
  Zd = { ec: 45, ecommerce: 46, linkid: 47 };
  var u = function (a, b, c) {
      b == N || b.get(V);
      var d = Yd.get(a);
      if (!ea(d)) return !1;
      b.plugins_ = b.plugins_ || new ee();
      if (b.plugins_.get(a)) return !0;
      b.plugins_.set(a, new d(b, c || {}));
      return !0;
    };
    var y = function(a, b, c, d, e) {
      if (!ea(Yd.get(b)) && !$d.get(b)) {
        Zd.hasOwnProperty(b) && J(Zd[b]);
        if (p.test(b)) {
          J(52);
          a = N.j(a);
          if (!a) return !0;
          c = d || {};
          d = { id: b, B: c.dataLayer || 'dataLayer', ia: !!a.get('anonymizeIp'), sync: e, G: !1 };
          a.get('&gtm') == b && (d.G = !0);
          var g = String(a.get('name'));
          't0' != g && (d.target = g);
          G(String(a.get('trackingId'))) ||
            ((d.clientId = String(a.get(Q))),
            (d.ka = Number(a.get(n))),
            (c = c.palindrome ? r : q),
            (c = (c = M.cookie.replace(/^|(; +)/g, ';').match(c))
              ? c
                  .sort()
                  .join('')
                  .substring(1)
              : void 0),
            (d.la = c),
            (d.qa = E(a.b.get(kb) || '', 'gclid')));
          a = d.B;
          c = new Date().getTime();
          O[a] = O[a] || [];
          c = { 'gtm.start': c };
          e || (c.event = 'gtm.js');
          O[a].push(c);
          c = t(d);
        }
        !c && Zd.hasOwnProperty(b) ? (J(39), (c = b + '.js')) : J(43);
        c &&
          ((c && 0 <= c.indexOf('/')) ||
            (c =
              (Ba || 'https:' == M.location.protocol ? 'https:' : 'http:') +
              '//www.google-analytics.com/plugins/ua/' +
              c),
          (d = ae(c)),
          (a = d.protocol),
          (c = M.location.protocol),
          ('https:' == a || a == c || ('http:' != a ? 0 : 'http:' == c)) &&
            B(d) &&
            (wa(d.url, void 0, e), $d.set(b, !0)));
      }
    };
    var v = function(a, b) {
      var c = A.get(a) || [];
      c.push(b);
      A.set(a, c);
    };
    var C = function(a, b) {
      Yd.set(a, b);
      b = A.get(a) || [];
      for (var c = 0; c < b.length; c++) b[c]();
      A.set(a, []);
    };
    var B = function(a) {
      var b = ae(M.location.href);
      if (D(a.url, 'https://www.google-analytics.com/gtm/js?id=')) return !0;
      if (a.query || 0 <= a.url.indexOf('?') || 0 <= a.path.indexOf('://')) return !1;
      if (a.host == b.host && a.port == b.port) return !0;
      b = 'http:' == a.protocol ? 80 : 443;
      return 'www.google-analytics.com' == a.host && (a.port || b) == b && D(a.path, '/plugins/') ? !0 : !1;
    };
    var ae = function (a) {
      function b (a) {
        var b = a.hostname || '';
          var c = b.indexOf(']') >= 0;
        b = b.split(c ? ']' : ':')[0].toLowerCase();
        c && (b += ']');
        c = (a.protocol || '').toLowerCase();
        c = 1 * a.port || (c == 'http:' ? 80 : c == 'https:' ? 443 : '');
        a = a.pathname || '';
        D(a, '/') || (a = '/' + a);
        return [b, '' + c, a];
      }
      var c = M.createElement('a');
      c.href = M.location.href;
      var d = (c.protocol || '').toLowerCase();
        var e = b(c);
        var g = c.search || '';
        var ca = d + '//' + e[0] + (e[1] ? ':' + e[1] : '');
      D(a, '//')
        ? (a = d + a)
        : D(a, '/')
          ? (a = ca + a)
          : !a || D(a, '?')
            ? (a = ca + e[2] + (a || g))
            : a.split('/')[0].indexOf(':') < 0 && (a = ca + e[2].substring(0, e[2].lastIndexOf('/')) + '/' + a);
      c.href = a;
      d = b(c);
      return {
        protocol: (c.protocol || '').toLowerCase(),
        host: d[0],
        port: d[1],
        path: d[2],
        query: c.search || '',
        url: a || '',
      };
    };
  var Z = {
    ga: function () {
      Z.f = [];
    },
  };
  Z.ga();
  Z.D = function (a) {
    var b = Z.J.apply(Z, arguments);
    b = Z.f.concat(b);
    for (Z.f = []; b.length > 0 && !Z.v(b[0]) && !(b.shift(), Z.f.length > 0););
    Z.f = Z.f.concat(b);
  };
  Z.J = function (a) {
    for (var b = [], c = 0; c < arguments.length; c++)
      {try {
        var d = new sc(arguments[c]);
        d.g ? C(d.a[0], d.a[1]) : (d.i && (d.ha = y(d.c, d.a[0], d.X, d.W)), b.push(d));
      } catch (e) {}}
    return b;
  };
  Z.v = function (a) {
    try {
      if (a.u) a.u.call(O, N.j('t0'));
      else {
        var b = a.c == gb ? N : N.j(a.c);
        if (a.A) {
          if (a.c == 't0' && ((b = N.create.apply(N, a.a)), b === null)) return !0;
        } else if (a.ba) N.remove(a.c);
        else if (b)
          {if (a.i) {
            if ((a.ha && (a.ha = y(a.c, a.a[0], a.X, a.W)), !u(a.a[0], b, a.W))) return !0;
          } else if (a.K) {
            var c = a.C,
              d = a.a,
              e = b.plugins_.get(a.K);
            e[c].apply(e, d);
          } else b[a.C].apply(b, a.a);}
      }
    } catch (g) {}
  };
  var N = function (a) {
    J(1);
    Z.D.apply(Z, [arguments]);
  };
  N.h = {};
  N.P = [];
  N.L = 0;
  N.ya = 0;
  N.answer = 42;
  var uc = [Na, W, V];
  N.create = function (a) {
    var b = za(uc, [].slice.call(arguments));
    b[V] || (b[V] = 't0');
    var c = '' + b[V];
    if (N.h[c]) return N.h[c];
    if (da(b)) return null;
    b = new pc(b);
    N.h[c] = b;
    N.P.push(b);
    return b;
  };
  N.remove = function (a) {
    for (var b = 0; b < N.P.length; b++)
      {if (N.P[b].get(V) == a) {
        N.P.splice(b, 1);
        N.h[a] = null;
        break;
      }}
  };
  N.j = function (a) {
    return N.h[a];
  };
  N.getAll = function () {
    return N.P.slice(0);
  };
  N.N = function () {
    gb != 'ga' && J(49);
    var a = O[gb];
    if (!a || a.answer != 42) {
      N.L = a && a.l;
      N.ya = 1 * new Date();
      N.loaded = !0;
      var b = (O[gb] = N);
      X('create', b, b.create);
      X('remove', b, b.remove);
      X('getByName', b, b.j, 5);
      X('getAll', b, b.getAll, 6);
      b = pc.prototype;
      X('get', b, b.get, 7);
      X('set', b, b.set, 4);
      X('send', b, b.send);
      X('requireSync', b, b.ma);
      b = Ya.prototype;
      X('get', b, b.get);
      X('set', b, b.set);
      if (M.location.protocol != 'https:' && !Ba) {
        a: {
          b = M.getElementsByTagName('script');
          for (var c = 0; c < b.length && c < 100; c++) {
            var d = b[c].src;
            if (d && d.indexOf('https://www.google-analytics.com/analytics') == 0) {
              b = !0;
              break a;
            }
          }
          b = !1;
        }
        b && (Ba = !0);
      }
      (O.gaplugins = O.gaplugins || {}).Linker = Dc;
      b = Dc.prototype;
      C('linker', Dc);
      X('decorate', b, b.ca, 20);
      X('autoLink', b, b.S, 25);
      C('displayfeatures', fd);
      C('adfeatures', fd);
      a = a && a.q;
      ka(a) ? Z.D.apply(N, a) : J(50);
    }
  };
  N.da = function () {
    for (var a = N.getAll(), b = 0; b < a.length; b++) a[b].get(V);
  };
  var te = N.N;
    var ve = O[gb];
  ve && ve.r ? te() : z(te);
  z(function () {
    Z.D(['provide', 'render', ua]);
  });
})(window);

// Google Analytics Tracking snippet needed for angulartics2 package (CM)
(function (i, s, o, g, r, a, m) {
  i.GoogleAnalyticsObject = r;
  (i[r] = // eslint-disable-line
    i[r] ||
    function () {
      (i[r].q = i[r].q || []).push(arguments);
    }), // eslint-disable-line
  (i[r].l = 1 * new Date());
  (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]); // eslint-disable-line
  a.async = 1;
  a.src = g;
  m.parentNode.insertBefore(a, m);
})(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
